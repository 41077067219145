<template>
  <div class="my-share">
    <fb-header slot="header" title="我的推广" fixed>
      <fb-button slot="left" icon="back" size="small" @click="goBack" type="primary"> 返回 </fb-button>
    </fb-header>
    <div class="share-content">
      <div class="content-item first">
        <span>累计佣金：</span>
        <span v-if="result && result.brokerage !== null">{{ result.brokerage }}元</span>
      </div>
      <div class="content-item">
        <span>作品数：</span>
        <span v-if="result"
          ><span class="item-num">{{ result.worksNum }}</span> 件</span
        >
      </div>
      <div class="content-item">
        <span>累计宣传：</span>
        <span v-if="result"
          ><span class="item-num">{{ result.cumulativePublicity }}</span> 人</span
        >
      </div>
      <div class="content-item" @click="$router.push('/user/myRegister')">
        <span>邀请注册：</span>
        <span v-if="result"
          ><span class="item-num">{{ result.registerNum }}</span> 人</span
        >
      </div>
      <div class="content-item" @click="$router.push('/user/myBuy')">
        <span>购买返现：</span>
        <span v-if="result"
          ><span class="item-num">{{ result.cashBackBuyersNum }}</span> 元</span
        >
      </div>
    </div>
    <div class="invite-text" @click="continuInvite">继续邀请，获取更多返利。</div>
    <van-action-sheet v-model="showInvite" :round="false">
      <div v-show="isVip" class="share-code">
        <div class="icons">
          <div class="icon" @click="weChatInvite">
            <i class="iconfont icon-weixin1"></i>
            <span>微信邀请</span>
          </div>
          <div class="icon" @click="shortMsgInvite">
            <i class="iconfont icon-xinxi"></i>
            <span>短信邀请</span>
          </div>
        </div>
        <div class="code">
          <div class="qrcode" id="qrcode" style="width: 180px; height: 180px"></div>
          <span>我的分享码</span>
        </div>
      </div>
      <fb-invite v-show="!isVip" @close="showInvite = false" :showHeadAndHistory="true" />
    </van-action-sheet>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'my-share',
  data() {
    return {
      showInvite: false,
      isVip: true,
      result: null,
      resultLoding: false,
      promotionRecordId: '',
      qrcode: null,
      shareUrl: '',
    }
  },
  computed: {
    ua() {
      return navigator.userAgent
    },
    ...mapGetters('user', ['userId']),
    ...mapState('user', ['userInfo']),
  },
  methods: {
    async getInit() {
      this.resultLoding = true
      try {
        const { data } = await this.$axios.post(`${this.$fissionBase}/fission/statistics/myPromotion`)
        if (data.code === '200') {
          this.result = data.data
        }
        this.resultLoding = false
      } catch (err) {
        this.resultLoding = false
        console.log(err)
      }
      console.log(this.result, 'getInit data')
    },
    goBack() {
      if (this.$router.length <= 1) {
        this.backToNative()
      } else {
        this.$router.back()
      }
    },
    backToNative() {
      const iOSUA = 'fb-iOS-Webview'
      const AndroidUA = 'fb-Android-Webview'
      const flutterUA = 'fb-flutter-Webview'

      const ua = navigator.userAgent
      if (ua === AndroidUA) {
        try {
          console.log('AndroidUA**********')
          // eslint-disable-next-line
          AND2JS.back()
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === iOSUA) {
        try {
          console.log('iOSUA**********')
          // eslint-disable-next-line
          window.webkit.messageHandlers.back.postMessage({})
        } catch {
          this.$notify('没有可以返回的页面')
        }
      } else if (ua === flutterUA) {
        try {
          // eslint-disable-next-line
          window.back.postMessage('{}')
        } catch (err) {
          this.$notify('没有可以返回的页面')
          console.log('back.postMessage', err)
        }
      } else {
        console.log('router**********')
        this.$router.back()
      }
    },
    async continuInvite() {
      this.showInvite = true
      const { data } = await this.$axios.post(`${this.$fissionBase}/fission/promotionRecord/generateInvitation`)
      if (data.code === '200' && data.data) {
        this.promotionRecordId = data.data.promotionRecordId
      }
      if (this.$base === 'https://api.farbun.com') {
        this.shareUrl = `https://tool.farbun.com/transform/sharevideo?id=${this.promotionRecordId}`
      } else if (this.$base === 'https://api.dev.farbun.com') {
        this.shareUrl = `https://dev.tool.farbun.com/transform/sharevideo?id=${this.promotionRecordId}`
      }
      if (this.qrcode) {
        return
      }
      this.qrcode = new QRCode('qrcode', {
        text: this.shareUrl,
        width: 180, // 图像宽度
        height: 180, // 图像高度
        colorDark: '#000000', // 前景色
        colorLight: '#ffffff', // 背景色
        typeNumber: 4,
        correctLevel: QRCode.CorrectLevel.H, // 容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
        callBack: (res) => {
          console.log(res, '---res')
        },
      })

      // qrcode.clear() // 清除二维码
      // qrcode.makeCode(url) // 生成另一个新的二维码
    },
    weChatInvite() {
      const shareUrl = this.shareUrl
      const name = '您的好友给您推荐了一款律师办公神器。点击查看:'
      console.log('weChatInvite')
      if (this.ua === this.$ua.a) {
        // eslint-disable-next-line
        AND2JS.shareCaseFile(name, window.encodeURI(shareUrl))
      } else if (this.ua === this.$ua.i) {
        // ios环境
        // eslint-disable-next-line
        window.webkit.messageHandlers.shareCaseFile.postMessage(
          JSON.stringify({
            filename: name,
            url: window.encodeURI(shareUrl),
          })
        )
      } else {
        this.$notify('网页端暂不支持分享')
        console.log('网页端暂不支持分享', shareUrl)
      }
    },
    // 原生发送短信
    shortMsgInvite() {
      // sms:后面跟收件人的手机号,body后接短信内容
      const text = '您的好友给您推荐了一款律师办公神器。点击查看:' + this.shareUrl
      const transfer = document.createElement('input')
      document.body.appendChild(transfer)
      transfer.value = text // 这里表示想要复制的内容
      transfer.select()
      if (document.execCommand('copy')) {
        document.execCommand('copy')
      }
      transfer.blur()
      document.body.removeChild(transfer)
      if (this.ua === this.$ua.a) {
        window.location.href = 'sms:?body=' + text
      } else if (this.ua === this.$ua.i) {
        window.location.href = 'sms:&body=' + text
      } else {
        window.location.href = 'sms:?body=' + text
        // this.$notify('网页端内容已粘贴到粘贴板上，不支持打开短信功能')
        console.log(text)
      }
    },
  },
  mounted() {
    console.log(this.userInfo, 'userInfo====')
    this.isVip = this.userInfo.memberStatus === 1
    this.getInit()
  },
}
</script>

<style lang="stylus" scoped>
.share-content {
  padding-top: 50px;
}
.share-content
  width 100%
  .content-item
    display flex
    justify-content space-between
    font-size 16px
    color #666
    height 40px
    line-height 40px
    margin-bottom 10px
    padding 0 20px
    & span:nth-child(1)
      width 100px
    & span:nth-child(2)
      flex 1
      font-size 22px
    & .item-num
      color #409eff
  .first
    color red
    & span:nth-child(2)
      font-size 26px
.invite-text
  width 100%
  margin 80px auto
  text-align center
  font-size 16px
  color #409eff
.my-share .van-action-sheet
  max-height 95%
.share-code
  height 60vh
  padding 20px 10px
  .icons
    height 100px
    display flex
    justify-content space-around
    align-items center
    .icon
      width 90px
      cursor pointer
      display flex
      flex-direction column
      justify-content center
      align-items center
      font-size 18px
      line-height 36px
      .iconfont
        font-size 50px
        line-height 60px
        color green
  .code
    width 90%
    margin 0 auto
    font-size 15px
    text-align center
    .qrcode
      margin 15px auto
      // background red
</style>
