var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-share" },
    [
      _c(
        "fb-header",
        {
          attrs: { slot: "header", title: "我的推广", fixed: "" },
          slot: "header",
        },
        [
          _c(
            "fb-button",
            {
              attrs: {
                slot: "left",
                icon: "back",
                size: "small",
                type: "primary",
              },
              on: { click: _vm.goBack },
              slot: "left",
            },
            [_vm._v(" 返回 ")]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "share-content" }, [
        _c("div", { staticClass: "content-item first" }, [
          _c("span", [_vm._v("累计佣金：")]),
          _vm.result && _vm.result.brokerage !== null
            ? _c("span", [_vm._v(_vm._s(_vm.result.brokerage) + "元")])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "content-item" }, [
          _c("span", [_vm._v("作品数：")]),
          _vm.result
            ? _c("span", [
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.result.worksNum)),
                ]),
                _vm._v(" 件"),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "content-item" }, [
          _c("span", [_vm._v("累计宣传：")]),
          _vm.result
            ? _c("span", [
                _c("span", { staticClass: "item-num" }, [
                  _vm._v(_vm._s(_vm.result.cumulativePublicity)),
                ]),
                _vm._v(" 人"),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          {
            staticClass: "content-item",
            on: {
              click: function ($event) {
                return _vm.$router.push("/user/myRegister")
              },
            },
          },
          [
            _c("span", [_vm._v("邀请注册：")]),
            _vm.result
              ? _c("span", [
                  _c("span", { staticClass: "item-num" }, [
                    _vm._v(_vm._s(_vm.result.registerNum)),
                  ]),
                  _vm._v(" 人"),
                ])
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "content-item",
            on: {
              click: function ($event) {
                return _vm.$router.push("/user/myBuy")
              },
            },
          },
          [
            _c("span", [_vm._v("购买返现：")]),
            _vm.result
              ? _c("span", [
                  _c("span", { staticClass: "item-num" }, [
                    _vm._v(_vm._s(_vm.result.cashBackBuyersNum)),
                  ]),
                  _vm._v(" 元"),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "invite-text", on: { click: _vm.continuInvite } },
        [_vm._v("继续邀请，获取更多返利。")]
      ),
      _c(
        "van-action-sheet",
        {
          attrs: { round: false },
          model: {
            value: _vm.showInvite,
            callback: function ($$v) {
              _vm.showInvite = $$v
            },
            expression: "showInvite",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isVip,
                  expression: "isVip",
                },
              ],
              staticClass: "share-code",
            },
            [
              _c("div", { staticClass: "icons" }, [
                _c(
                  "div",
                  { staticClass: "icon", on: { click: _vm.weChatInvite } },
                  [
                    _c("i", { staticClass: "iconfont icon-weixin1" }),
                    _c("span", [_vm._v("微信邀请")]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "icon", on: { click: _vm.shortMsgInvite } },
                  [
                    _c("i", { staticClass: "iconfont icon-xinxi" }),
                    _c("span", [_vm._v("短信邀请")]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "code" }, [
                _c("div", {
                  staticClass: "qrcode",
                  staticStyle: { width: "180px", height: "180px" },
                  attrs: { id: "qrcode" },
                }),
                _c("span", [_vm._v("我的分享码")]),
              ]),
            ]
          ),
          _c("fb-invite", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.isVip,
                expression: "!isVip",
              },
            ],
            attrs: { showHeadAndHistory: true },
            on: {
              close: function ($event) {
                _vm.showInvite = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }